import { initializeApp } from "firebase/app";
import type { UserCredential } from "@firebase/auth";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA-jMdNOge4upR4jx_wQu5BCbrr9xchGSU",
  authDomain: "pricer-e8331.firebaseapp.com",
  projectId: "pricer-e8331",
  storageBucket: "pricer-e8331.appspot.com",
  messagingSenderId: "408223315176",
  appId: "1:408223315176:web:33e3aa57b90a88106e055d",
  measurementId: "G-NXH9TE42P4",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle: () => Promise<
  UserCredential | undefined
> = async () => {
  try {
    return await signInWithPopup(auth, googleProvider);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, signInWithGoogle, logout };