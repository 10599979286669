import type { MenuProps } from "antd";
import { Avatar, Dropdown, Space } from "antd";
import styles from "./AppHeader.module.css";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import * as React from "react";
import { auth, logout, signInWithGoogle } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { CART_LOCAL_STORAGE_KEY } from "../../utils/consts";
import type { IItem } from "../../contexts/types";
import { saveUser } from "../../UIServices/UserService";

function LeftMenu() {
  const login = async () => {
    const user = await signInWithGoogle();
    if (user) {
      const guestCartString = localStorage.getItem(CART_LOCAL_STORAGE_KEY);
      const guestCart: IItem[] = guestCartString
        ? JSON.parse(guestCartString)
        : [];
      await saveUser(user, guestCart);
    }
  };

  const [user] = useAuthState(auth);
  const items: MenuProps["items"] = [
    {
      label: <a href="">התנתקות</a>,
      key: "0",
    },
  ];

  const avatarClicked = () => {
    !!user ? logout() : login();
  };

  return !!user ? (
    <Dropdown menu={{ items, onClick: avatarClicked }} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <div className={styles.right}>
            <Avatar size="large" src={user.photoURL} />
            <DownOutlined />
          </div>
        </Space>
      </a>
    </Dropdown>
  ) : (
    <a onClick={avatarClicked}>
      <Avatar size="small" icon={<UserOutlined />} />
    </a>
  );
}
export default LeftMenu;
