import styles from "./ItemSearchbar.module.css";
import {SearchBox} from "react-instantsearch-hooks-web";
import * as React from "react";
import "instantsearch.css/themes/satellite.css";
import {useRouter} from "next/router";

type Props = {
  onSearch: () => void;
};
const ItemSearchbar = ({onSearch}: Props) => {
  const router = useRouter();
  const isItemPage = router.pathname.includes("item");
  const placeholder = "חיפוש מוצרים";

  const doSearch = () => {
    onSearch();
    router.push({pathname: "../"});
  };
  return (
      <>
        <div className={styles.itemFinder}>
          {isItemPage ? (
              <SearchBox
                  placeholder={placeholder}
                  onSubmit={doSearch}
                  className={styles.theinput}
              />
          ) : (
              <SearchBox placeholder={placeholder}/>
          )}
        </div>
      </>
  );
};

export default ItemSearchbar;
