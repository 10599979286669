import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { typesenseServerConfig } from "./config";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: typesenseServerConfig,
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "title",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default searchClient;
