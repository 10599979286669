import ShufersalLogo from "../public/images/ShufersalLogo.png";
import RamiLogo from "../public/images/RamiLogo.png";
import VictoryLogo from "../public/images/VictoryLogo.png";
import AshukLogo from "../public/images/AshukLogo.png";
import HaziHinamLogo from "../public/images/HaziHinamLogo.png";
import YohananofLogo from "../public/images/YohananofLogo.png";
import * as React from "react";
import type { User } from "@firebase/auth";
import type { IItem, IPromo } from "../contexts/types";
import { getItemsByCode } from "../DBServices/ItemService";

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
}

export type SitePriceMap = {
  [key: number]: {
    price: number;
    pricePerMeasuredUnit: number;
    measureUnit: string;
  };
};

export async function fetchJson<T>(
  url: string,
  options: RequestInit = {}
): Promise<T> {
  const response = await fetch(url, options);
  return response.json();
}

export async function fetchJsonWithAuth<T>(
  authUser: User,
  url: string,
  options: RequestInit = {}
): Promise<T> {
  const token = await authUser.getIdToken();
  if (token) {
    const headers = buildHeaders(token);
    options = { ...options, headers };
  }

  return fetchJson(url, options);
}

export function buildHeaders(token: string | null): HeadersInit | undefined {
  if (!token) {
    return;
  }

  const headers: HeadersInit = {};
  headers.Authorization = token;
  return headers;
}

export function getLogoSrc(siteID: number) {
  let image;
  switch (siteID) {
    case 1:
      image = ShufersalLogo;
      break;
    case 2:
      image = RamiLogo;
      break;
    case 3:
      image = VictoryLogo;
      break;
    case 4:
      image = AshukLogo;
      break;
    case 5:
      image = { src: "https://i.imgur.com/btqi6QP.png" };
      break;
    case 6:
      image = HaziHinamLogo;
      break;
    case 7:
      image = YohananofLogo;
      break;
    default:
      image = ShufersalLogo;
      console.warn("Logo not found for ", siteID);
      break;
  }
  return image.src;
}

export type TPricePerSite = {
  siteID: string;
  minPrice: number;
  maxPrice: number;
  promo?: IPromo | null;
  pricePerMeasuredUnit: number;
  measureUnit: string;
  cheapestItem: IItem;
};

export function getSitePrices(items: IItem[]) {
  const minPricePerSite: { [key: string]: number } = {};
  const maxPricePerSite: { [key: string]: number } = {};
  const pricePerSite: TPricePerSite[] = [];
  const itemPerSite: { [key: string]: IItem } = {};
  items.forEach((item) => {
    if (
      !maxPricePerSite[item.site_id] ||
      +item.price > +maxPricePerSite[item.site_id]
    ) {
      maxPricePerSite[item.site_id] = item.price;
    }
    if (
      !minPricePerSite[item.site_id] ||
      +item.price < +minPricePerSite[item.site_id]
    ) {
      minPricePerSite[item.site_id] = item.price;
      itemPerSite[item.site_id] = item;
    }
  });
  Object.keys(minPricePerSite).map((siteID) => {
    pricePerSite.push({
      siteID,
      minPrice: +minPricePerSite[siteID],
      maxPrice: +maxPricePerSite[siteID],
      pricePerMeasuredUnit: items[0].unit_adjusted_price,
      measureUnit: items[0].measure_unit,
      cheapestItem: itemPerSite[siteID],
    });
  });
  return pricePerSite;
}

export function isMod(uid: string) {
  return uid === "qnNXd7eo9PMOlnBE0wePkgrOk5E2";
}
