export const typesenseServerConfig = {
  apiKey: "xyz", // Be sure to use an API key that only allows search operations
  nodes: [
    {
      host:
        process.env.NODE_ENV === "production"
          ? "search.wiseprice.co.il"
          : "vps787922.ovh.net",
      port: process.env.NODE_ENV === "production" ? 443 : 8108,
      protocol: process.env.NODE_ENV === "production" ? "https" : "http",
    },
  ],
  cacheSearchResultsForSeconds: 2 * 60,
};
