import "../styles/globals.css";
import React from "react";
import { ItemContextProvider } from "../contexts/ItemContext";
import { ConfigProvider, Layout } from "antd";

const { Content } = Layout;

import AppHeader from "../components/Layout/AppHeader";
import NextNProgress from "nextjs-progressbar";
import searchClient from "../config/typesense";
import { InstantSearch } from "react-instantsearch-hooks";

import moment from "moment";
import "moment/locale/he";

moment.locale("he");
import type { ThemeConfig } from "antd/es/config-provider";
import { QueryClient, QueryClientProvider } from "react-query";

function MyApp({ Component, pageProps }: any) {
  const theme: ThemeConfig = {
    token: {
      fontSizeHeading1: 28,
    },
  };
  const queryClient = new QueryClient();

  return (
    <ConfigProvider direction="rtl" theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ItemContextProvider>
          <InstantSearch
            indexName="items"
            searchClient={searchClient}
            routing={true}
          >
            <AppHeader />
            <Content className={"container"}>
              <NextNProgress />
              <Component {...pageProps} />
            </Content>
          </InstantSearch>
        </ItemContextProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default MyApp;
