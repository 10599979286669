import * as React from "react";
import Logo from "../../public/images/logo.svg";
import styles from "./AppHeader.module.css";
import { Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Link from "next/link";
import ItemSearchbar from "../ItemSearchbar/ItemSearchbar";
import { useState } from "react";
import useDevice from "../../hooks/useDevice";
import LeftMenu from "./LeftMenu";

const AppHeader = () => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useDevice();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div className={styles.topNav}>
      <div className={styles.logo}>
        <Link href="/">
          <Logo height={36} />
        </Link>
      </div>
      {!isMobile && <ItemSearchbar onSearch={() => handleOpenChange(false)} />}

      {isMobile && (
        <Popover
          content={<ItemSearchbar onSearch={() => handleOpenChange(false)} />}
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        />
      )}

      <LeftMenu />

      {isMobile && <SearchOutlined onClick={() => setOpen(true)} />}
    </div>
  );
};

export default AppHeader;
