import { RequestMethod } from "../utils/common";
import type { UserCredential } from "@firebase/auth";
import type {IItem} from "../contexts/types";

export async function saveUser(user: UserCredential, guestCart: IItem[]) {
  return fetch("/api/user", {
    method: RequestMethod.POST,
    body: JSON.stringify({
      user,
      guestCart,
    }),
  });
}