import type { FC } from "react";
import React, { createContext, useReducer } from "react";

export type ItemDataContextType = {
  searchQuery: string;
};
const initialState: ItemDataContextType = {
  searchQuery: "",
};
export const ItemContext = createContext(initialState);

type UpdateSearchQuery = {
  type: "UPDATE_SEARCH_QUERY";
  payload: {
    searchQuery: string;
  };
};

type Action = UpdateSearchQuery; // add more actions here

export const ItemDispatchContext = createContext<React.Dispatch<Action>>(() => {
  console.error(
    "Default dispatch used. You are probably using this context without the Provider."
  );
});

function itemReducer(
  state: ItemDataContextType,
  action: Action
): ItemDataContextType {
  const { type } = action;
  switch (type) {
    case "UPDATE_SEARCH_QUERY":
      const { searchQuery } = action.payload;
      return { ...state, searchQuery };
      break;
    default: {
      console.error("Unknown action", type);
      return state;
    }
  }
}

export const ItemContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(itemReducer, initialState);

  return (
    <ItemContext.Provider value={state}>
      <ItemDispatchContext.Provider value={dispatch}>
        {children}
      </ItemDispatchContext.Provider>
    </ItemContext.Provider>
  );
};
